function doAction(fetchMoreButton) {
  // eslint-disable-line
  // get reference to div's
  const parentDiv = fetchMoreButton.lastElementChild;
  const baseDataSet = parentDiv.dataset;

  // get data for the loader
  const { url } = baseDataSet;
  const { postsPerPage } = baseDataSet;
  const { postType } = baseDataSet;
  const { paged } = baseDataSet;
  const { nonce } = parentDiv;
  const { searchTerm } = baseDataSet;

  // Assign data to FormData
  const formData = new FormData(); // eslint-disable-line
  formData.append("posts_per_page", postsPerPage);
  formData.append("paged", paged);
  formData.append("post_type", postType);
  formData.append("nonce", nonce);
  formData.append("action", "ajaxCallback");
  formData.append("search_term", searchTerm);
  const params = new URLSearchParams(formData);

  // start loader
  const fetchMoreContent = document.getElementById("dw-fetchmore-content"); // eslint-disable-line
  fetchMoreContent.classList.add("hide");
  const spinner = document.getElementById("fetchmore-spinner"); // eslint-disable-line
  spinner.classList.add("show");

  // fetch the data
  fetch(url, {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json'
    // },
    body: params,
  })
    .then((res) => res.json())
    .then((response) => {
      if (response === 0 || response.status === "error") {
        // if error
        return;
      }

      // stop the loader
      fetchMoreContent.classList.remove("hide");
      spinner.classList.remove("show");

      // response was success
      // hide load more if max paged atteint
      if (response.paged >= response.max) {
        fetchMoreContent.style.display = "none";
      }

      // add paged number to button
      baseDataSet.paged = response.paged;

      // add posts to the div
      const fetchMore = document.getElementById("dw-fetchmore"); // eslint-disable-line
      fetchMore.previousElementSibling.innerHTML += response.posts;
    })
    .catch((error) => error);
}

export default function fetchMorePosts() {
  const fetchMoreButton = document.getElementById("dw-fetchmore-activator"); // eslint-disable-line

  if (!fetchMoreButton) {
    return;
  }

  fetchMoreButton.addEventListener("click", () => doAction(fetchMoreButton));
}
